<template>
  <div class="">
    <el-row :gutter="10" style="min-height: 60px;" v-loading="shopConfig.isLoadData">
      <template v-if="shopConfig.data && shopConfig.data.length > 0">
        <template v-for="shop in shopConfig.data">
          <el-col :span="4" :key="shop.shopid">
            <router-link :to="'/service-provider/view/' + shop.shopid">
              <div class="sctp-bg-white">
                <div>
                  <el-image
                    :src="shop.logo"
                    style="height: 150px;width: 100%;"
                    fit="cover"></el-image>
                </div>
                <div class="sctp-pad-b5 sctp-pad-lr5">
                  <h3 class="sctp-ellipsis--l2">{{ shop.shopname }}</h3>
                </div>
                <div @click.prevent="" class="flex flex-sb pad-lr5">
                  <div></div>
                  <div>
                    <el-dropdown @command="handleCommand($event, shop)" size="mini">
                      <i class="el-icon-more"></i>
                      <el-dropdown-menu slot="dropdown">
                        <template v-for="item in dropdownOptions(shop)">
                          <el-dropdown-item :command="item">{{ item.label }}</el-dropdown-item>
                        </template>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </div>
            </router-link>
          </el-col>
        </template>
      </template>
      <template v-else>
        <div class="empty-text">暂无数据</div>
      </template>
    </el-row>
    <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe sctp-bg-white sctp-mar-t10">
      <el-pagination
        :current-page="shopConfig.pageConfig.page"
        :page-size="shopConfig.pageConfig.limit"
        @size-change="shopConfig.pageConfig.handleSizeChange"
        @current-change="shopConfig.pageConfig.handleCurrentChange"
        :page-sizes="shopConfig.pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="shopConfig.pageConfig.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {collection} from "@/apis/index";

const config = require('../../../../resources/js/config');
export default {
  name: 'favorite-shop',
  data() {
    return {
      shopConfig: {
        isLoadData: false,
        data: [],
        loadData: () => {
          let {page, limit} = this.shopConfig.pageConfig;
          this.shopConfig.isLoadData = true;
          collection.getShop({
            userId: this.user.userId,
            page,
            limit,
          }).then(res => {
            const {retdata = [], count = 0} = res;
            this.shopConfig.data = retdata;
            this.shopConfig.pageConfig.total = count;
          }).finally(() => {
            this.shopConfig.isLoadData = false;
          })
        },
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 10),
          handleSizeChange: (limit) => {
            this.shopConfig.pageConfig.limit = limit;
            this.shopConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.shopConfig.pageConfig.page = page;
            this.shopConfig.loadData();
          }
        },
      },
      handleCommand: (command, item) => {
        command.command(item);
      },
    }
  },
  methods: {},
  computed: {
    dropdownOptions() {
      return function (item) {
        let options = [];
        options = [
          {
            label: '取消收藏', command: () => {
              collection.addFavorite({
                userId: (this.user && this.user.userId) || null,
                targetId: item.shopid,
                targetType: 1
              }).then(res => {
                const {retdata} = res;
                if (retdata) {
                  this.$message.success('收藏成功')
                } else {
                  this.$message.success('取消收藏成功')
                }
                this.shopConfig.loadData();
              });
            }
          },
        ];
        return options;
      }
    }
  },
  beforeMount() {
    this.shopConfig.loadData();
  }
}
</script>

<style scoped>
/*@formatter:off*/

  /*@formatter:on*/
</style>
